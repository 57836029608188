<template>
  <v-text-field
    :label="label"
    :value="value"
    :name="label"
    :placeholder="placeholder"
    @input="input($event)"
    @blur="blur"
    outlined
    :disabled="disabled"
    color="rgba(0, 0, 0, 0.87)"
    :error-messages="valueErrors"
    :required="required"
    type="number"
    :class="customClass"
  />
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minValue, integer } from "vuelidate/lib/validators";
export default {
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    minValue: {
      type: Number,
      required: false,
      default: 0
    },
    isInteger: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    disabled: {
      required: false,
      default: false
    },
    customClass: {
      type: String,
      required: false,
      default: ""
    }
  },
  mixins: [validationMixin],
  methods: {
    input(e) {
      this.$emit("input", e);
      this.$v.value.$touch();
    },
    blur() {
      this.$v.value.$touch();
    }
  },
  validations() {
    return {
      value: {
        required: this.required ? required : false,
        minValue: this.minValue ? minValue(this.minValue) : false,
        integer: this.isInteger ? integer : false
      }
    };
  },
  computed: {
    valueErrors() {
      const errors = [];
      if (!this.$v.value.$dirty) return errors;
      !this.$v.value.required && errors.push(`${this.label} is required`);
      !this.$v.value.minValue &&
        errors.push(`${this.label} must be bigger than ${this.minValue - 1}`);
      !this.$v.value.integer && errors.push(`${this.label} must be integer`);
      return errors;
    }
  }
};
</script>
